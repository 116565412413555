<template>
  <div class="reset">
    <div class="_container reset__container">
      <h1 class="reset__title">Введите новый пароль</h1>
      <VeeForm @submit="changePassword" class="reset__form">
        <div class="reset__item">
          <div>
            <VeeField
              name="password"
              :type="showPass ? 'password' : 'text'"
              ref="password"
              class="reset__input main-input"
              placeholder="Новый пароль"
              v-model="password"
              rules="required|min:8"
            />
            <span class="eye" @click="showPass = !showPass">
              <img
                :src="
                  require(`@/assets/icons/${
                    !showPass ? 'eyeshow' : 'eyehide'
                  }.png`)
                "
              />
            </span>
          </div>
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="password"
            ></VeeErrorMessage>
          </transition>
        </div>
        <div class="reset__item">
          <div>
            <VeeField
              name="passwordConfirm"
              :type="showPass1 ? 'password' : 'text'"
              class="reset__input main-input"
              placeholder="Введите пароль еще раз"
              v-model="passwordConfirm"
              rules="required|min:8"
            />
            <span class="eye" @click="showPass1 = !showPass1">
              <img
                :src="
                  require(`@/assets/icons/${
                    !showPass1 ? 'eyeshow' : 'eyehide'
                  }.png`)
                "
              />
            </span>
          </div>
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="passwordConfirm"
            ></VeeErrorMessage>
          </transition>
        </div>
        <button type="submit" class="reset__btn main-btn">
          Сбросить пароль
        </button>
      </VeeForm>
    </div>
    <ModalComponent
      :is-open="showModal"
      @close="
        showModal = false;
        $router.push('/login');
      "
    >
      <h2 class="reset__second-title second-title">Пароль успешно изменён!</h2>
      <router-link to="login" class="reset__link"
        >Перейти на страницу входа в аккаунт</router-link
      >
    </ModalComponent>
  </div>
</template>

<script>
import axios from "axios";
import ModalComponent from "@/components/ModalComponent";

export default {
  components: { ModalComponent },
  data() {
    return {
      showPass: true,
      showPass1: true,
      showModal: false,
      password: "",
      passwordConfirm: "",
    };
  },

  methods: {
    changePassword() {
      axios
        .post(
          "V1/reset-password",
          {
            new_password: this.password,
            new_password_confirmation: this.passwordConfirm,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((response) => {
          this.showModal = true;
        })
        .catch((errors) => {
        });
    },
  },
};
</script>

<style lang="scss">
.reset {
  padding: 100px 97px;
  @media (max-width: $mobile + px) {
    padding: 65px 0;
  }

  &__container {
    max-width: 460px;
  }

  &__form {
    &:not(:last-child) {
      margin: 0 0 30px 0;
    }
  }

  .modal__wrapper {
    padding: 45px 25px 50px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 37px 0;
    text-align: center;
    @media (max-width: $mobile + px) {
      font-size: 20px;
    }
  }

  &__second-title {
    margin: 0 0 25px 0;
  }

  &__link {
    color: #d81921;
    text-decoration: underline !important;
  }

  &__item {
    margin: 0 0 25px 0;
    div {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #77757f;
      .main-input {
        border: none;
        outline: none;
      }
      .eye {
        padding: 0 12px;
        img {
          width: 18px;
        }
      }
    }
  }

  &__btn {
    width: 100%;
  }
}
</style>